<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Pinning items</h3>

	<p><img srcset="/docimages/pinning-2.png 4x" class="k-help-img float-right">When browsing items on a full-size screen (i.e. not a smartphone), click the thumbtack (<v-icon small style="transform:rotate(30deg);" class="mx-1">fas fa-thumbtack</v-icon>) icon in the upper-right corner of an item <span v-html="link('item_card', 'card')"></span> to “pin” the item on the framework “desktop”. You can pin any number of items at the same time:</p>
	<img srcset="/docimages/pinning-1.png 3x" class="k-help-img block">
	<ul>
		<li>Pinning is useful if you want to refer back to one or more items while you examine other items.</li>
		<li>For unpinned items, the thumbtack icon will appear in green. If an item is already pinned, its thumbtack icon will be red, and you can click the icon to unpin it.</li>
		<li>Note that <span v-html="app_name"/> will “remember” items you’ve pinned for each framework if you close the site and re-open it later (as long as you re-open the site in the same browser and on the same computer you were using when you initially did your pinning), <i>even if you are not signed in</i>.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	